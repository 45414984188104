import { ComponentType, MouseEvent, MouseEventHandler, forwardRef } from 'react'

import { analytics } from 'utils/helpers/analytics'

export interface ClickAnalyticsProps {
  analyticsId?: string
  analyticsProps?: Record<string, string | number | boolean>
  onClick?: MouseEventHandler
  isDisabled?: boolean
}

export const withClickAnalytics = <T extends object>(
  WrappedComponent: ComponentType<T>
) =>
  forwardRef<HTMLElement, T & ClickAnalyticsProps>(
    ({ analyticsId, analyticsProps = {}, onClick, ...props }, ref) => {
      const handleClick = (e: MouseEvent): void => {
        if (props.isDisabled) {
          return
        }

        if (analyticsId) {
          analytics
            .trackWebEvent(analyticsId, {
              ...analyticsProps,
            })
            .catch(console.error)
        }
        onClick?.(e)
      }
      
      return <WrappedComponent ref={ref} onClick={handleClick} {...(props as T)} />
    }
  )
