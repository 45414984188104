import { useCollections } from '@reservoir0x/reservoir-kit-ui';
import { chains, nfts } from 'config/routes';
import { getChainsByContractId, getKeyByContractId } from 'utils/collection';
import { collectionIds } from 'utils/constants';
import supportedChains, { DefaultChain } from './chains';

const fetchCollections = (contractIds: string[], chainKey: string) => {
    const { data } = useCollections(
        {
            contract: contractIds,
        },
        {
            revalidateOnMount: true,
            refreshInterval: 300000,
        },
        chains?.[chainKey]?.chainId
    );
    return data || [];
};

const aggregateCollections = (collections: any[]) => {
    return collections.reduce((acc, current) => {
        const existingItem = acc.find((item: any) => item?.name === current?.name);
        if (existingItem) {
            existingItem.onSaleCount += Number(current?.onSaleCount);
        } else {
            acc.push({
                ...current,
                name: current.name,
                onSaleCount: Number(current?.onSaleCount),
            });
        }
        return acc;
    }, []);
};

export const getAllChainCollections = () => {
    const popNFTChains = Object.entries(chains);

    const collectionsList = popNFTChains.map(([chainKey]) => {
        return fetchCollections(
            [
                nfts?.[collectionIds.pirates]?.chains?.[chainKey],
                nfts?.[collectionIds.ships]?.chains?.[chainKey],
                nfts?.[collectionIds.items]?.chains?.[chainKey]
            ].filter(Boolean),
            chainKey
        );
    });

    // Flatten the array of arrays and aggregate as necessary
    const flattenedCollections = collectionsList.flat();
    return aggregateCollections(flattenedCollections);
};


export const getMultiChainCollections = (collectionSystemId: string) => {
    const popNFTChains = Object.entries(getChainsByContractId(collectionSystemId) ?? {});
    const collectionKey = getKeyByContractId(collectionSystemId);

    const collectionsList = popNFTChains.map(([chainKey]) => {
        return fetchCollections(
            [nfts?.[collectionKey]?.chains?.[chainKey]].filter(Boolean),
            chainKey
        );
    });
    return collectionsList.flat();

};

export const getSystemIdChains = (systemId: string) => {
    // Check if nfts[systemId] is defined
    if (!nfts[systemId]) {
        return { defaultChain: null, defaultReservoirChain: null, contract: null, id: null, supportedSystemIdChains: [] };
    }

    const supportedSystemIdChains = supportedChains.filter((supportedChain) =>
        Object.keys(nfts[systemId]?.chains || {}).includes(supportedChain.routePrefix)
    );
    const defaultChain =
        supportedSystemIdChains[0]?.routePrefix ?? DefaultChain.routePrefix
    const defaultReservoirChain = supportedSystemIdChains[0]

    const contract = nfts[systemId]?.chains[defaultChain]

    const id = contract

    return { defaultChain, defaultReservoirChain, contract, id, supportedSystemIdChains };
}

export const getSystemIdSupportedChains = (systemId: string) => {
    const supportedSystemIdChains = supportedChains.filter((supportedChain) =>
        nfts[systemId] && Object.keys(nfts[systemId].chains || {}).includes(supportedChain.routePrefix)
    )

    return supportedSystemIdChains;
}

export const getContractIdForSystemAndChainKey = (systemId: string, chainKey: string): string | null => {
    const system = nfts[systemId];
    if (!system || !system.chains || !system.chains[chainKey]) {
        return null;
    }
    return system.chains[chainKey];
}

export const getSystemIdFromChainAndAsset = (chain: string, assetId: string): string | null => {
    for (const [systemId, nft] of Object.entries(nfts)) {
        const chainValues = Object.values(nft.chains);
        if (chainValues.some((chain) => chain.toLowerCase() === assetId.toLowerCase())) {
            return systemId;
        }
    }

    return null;
}
