import supportedChains, { ReservoirChain } from 'utils/chains'
import { ConnectedWallet } from "@privy-io/react-auth"

export interface SystemIdToNFTs {
  [systemId: string]: {
    name: string
    chains: { [chain: string]: string }
  }
}
export interface Chain {
  name: string;
  id: string;
  chainId: number;
}

export interface Chains {
  [chain: string]: Chain;
}

const testnetChains = {
  sepolia: {
    name: 'Sepolia',
    id: 'sepolia',
    chainId: 11155111,
  },
  'apex-pop-testnet': {
    name: 'Barret',
    id: 'apex-pop-testnet',
    chainId: 70800,
  },
  cloud: {
    name: 'Cloud',
    id: 'cloud',
    chainId: 70805,
  },
}

const popTestnetChains = {
  'apex-pop-testnet': {
    name: 'Barret',
    id: 'apex-pop-testnet',
    chainId: 70800,
  },
  cloud: {
    name: 'Cloud',
    id: 'cloud',
    chainId: 70805,
  },
}

const mainnetChains = {
  mainnet: {
    name: 'Ethereum',
    id: 'mainnet',
    chainId: 1,
  },
  apex: {
    name: 'Apex',
    id: 'apex',
    chainId: 70700,
  },
  boss: {
    name: 'Boss',
    id: 'boss',
    chainId: 70701,
  },
}

const popMainnetChains = {
  apex: {
    name: 'Apex',
    id: 'apex',
    chainId: 70700,
  },
  boss: {
    name: 'Boss',
    id: 'boss',
    chainId: 70701,
  },
};

const testnetNFTs: SystemIdToNFTs = {
  'game.piratenation.piratenft': {
    name: "Founder's Pirates",
    chains: { sepolia: '0xd37213c95958aba5f50405969ab76f4c1ed835a3' },
  },
  'game.piratenation.tradeableshipnft': {
    name: 'Ships',
    chains: {
      'apex-pop-testnet': '0xc64ad9315246fbedf9a8c56c706d82e0140d3cff',
      cloud: '0xcdd7056a06a856cc66d0e326fa674b5ecefb9e65',
    },
  },
  'game.piratenation.tradeablegameitems': {
    name: 'Game Items',
    chains: {
      'apex-pop-testnet': '0xe81834dd734855213fb86274c628bb90d8e887e0',
      cloud: '0x0d730824284274aaa9d559ca06baf4d35aa72121',
    },
  },
}

const mainnetNFTs: SystemIdToNFTs = {
  'game.piratenation.piratenft': {
    name: "Founder's Pirates",
    chains: { mainnet: '0x1b41d54b3f8de13d58102c50d7431fd6aa1a2c48', ethereum: '0x1b41d54b3f8de13d58102c50d7431fd6aa1a2c48' },
  },
  'game.piratenation.tradeableshipnft': {
    name: 'Ships',
    chains: {
      'apex': '0x3c5094389b81aa0c5445b60d20d505535d549af1',
      boss: '0xCE4C0930087880a91b301a4Bf803bE40bEc45417',
    },
  },
  'game.piratenation.tradeablegameitems': {
    name: 'Game Items',
    chains: {
      'apex': '0xaf57145e0c09a75ca4a2dc65ac80c91920e537ce',
      boss: '0x87A9Ebf7cB0DE5c3ebc13E59006F58e553980d9d',
    },
  },
}

export const nfts: SystemIdToNFTs = process.env.NEXT_PUBLIC_PUBLIC_ENVIRONMENT === 'production' ? mainnetNFTs : testnetNFTs
export const chains: Chains = process.env.NEXT_PUBLIC_PUBLIC_ENVIRONMENT === 'production' ? mainnetChains : testnetChains
export const popChains: Chains = process.env.NEXT_PUBLIC_PUBLIC_ENVIRONMENT === 'production' ? popMainnetChains : popTestnetChains

export const getAllowedContracts = (chain: string) => {
  const allowedContracts = [] as string[];
  for (const nft of Object.values(nfts)) {
    if (nft.chains[chain]) {
      allowedContracts.push(nft.chains[chain])
    }
  }
  return allowedContracts
}

export const getContractAddressesForSystem = (systemId: string): string[] => {
  const system = nfts[systemId]
  if (!system) {
    return [];
  }

  // Remove duplicates
  return Array.from(new Set(Object.values(system.chains)))
}

export const getReservoirChainFromContract = (contractAddress: string): ReservoirChain | null => {
  // Normalize the contract address for comparison
  const normalizedContract = contractAddress.toLowerCase()

  // Search through all NFT configurations
  for (const systemConfig of Object.values(nfts)) {
    for (const [chainKey, address] of Object.entries(systemConfig.chains)) {
      if (address.toLowerCase() === normalizedContract) {
        // Found matching contract, now find corresponding ReservoirChain
        const reservoirChain = supportedChains.find(
          chain => ((chain.routePrefix === chainKey) || (chain.routePrefix === "ethereum" && chainKey === "mainnet"))
        )
        return reservoirChain || null
      }
    }
  }

  return null
}

export const getReservoirChainsFromContract = (contractAddress: string): ReservoirChain[] => {
  // Normalize the contract address for comparison
  const normalizedContract = contractAddress.toLowerCase();
  const matchingChains: ReservoirChain[] = [];

  // Search through all NFT configurations
  for (const systemConfig of Object.values(nfts)) {
    for (const [chainKey, address] of Object.entries(systemConfig.chains)) {
      if (address.toLowerCase() === normalizedContract) {
        // Find corresponding ReservoirChain
        const reservoirChain = supportedChains.find(
          chain => chain.routePrefix === chainKey
        );
        if (reservoirChain) {
          matchingChains.push(reservoirChain);
        }
      }
    }
  }

  return matchingChains;
}

export const getAssociatedMultichainReservoirChains = (contractAddress: string): ReservoirChain[] => {
  // Normalize the input contract address
  const normalizedContract = contractAddress.toLowerCase();
  const associatedChains: ReservoirChain[] = [];

  // Find the parent object containing this contract address
  let parentConfig: { chains: Record<string, string> } | null = null;

  // Search through all NFT configurations to find the parent
  for (const config of Object.values(nfts)) {
    // Check if this contract exists in any of the chain addresses
    const hasContract = Object.values(config.chains)
      .some(address => address.toLowerCase() === normalizedContract);

    if (hasContract) {
      parentConfig = config;
      break;
    }
  }

  // If we found the parent config, get ReservoirChain objects for ALL its chains
  if (parentConfig) {
    // Get all chain keys from the parent's chains object
    Object.keys(parentConfig.chains).forEach(chainKey => {
      const reservoirChain = supportedChains.find(
        chain => chain.routePrefix === chainKey
      );
      if (reservoirChain) {
        associatedChains.push(reservoirChain);
      }
    });
  }

  return associatedChains;
}

export const getContractAddressForSystemAndChain = (systemId: string, chain: string): string | null => {
  const system = nfts[systemId]
  if (!system || !system.chains[chain]) {
    return null
  }
  return system.chains[chain]
}

export const getParentSystemIdFromContract = (contractAddress: string): string | null => {
  const normalizedContract = contractAddress.toLowerCase();

  for (const [systemId, system] of Object.entries(nfts)) {
    if (system.chains) {
      for (const address of Object.values(system.chains)) {
        if (address.toLowerCase() === normalizedContract) {
          // Return the parent system ID
          return systemId;
        }
      }
    }
  }

  return '';
}

