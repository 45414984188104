import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react'
import {
  fetchCollectionData,
  partitionCollectionsByTokenCountV2,
} from 'utils/mergeLiquidity'
import { useRouter } from 'next/router'
import supportedChains from 'utils/chains'
import { nfts } from 'config/routes'
import { paths } from '@reservoir0x/reservoir-sdk'

type CollectionResponse =
  paths['/collections/v7']['get']['responses']['200']['schema']

interface MergeCollectionsContextType {
  maxTokenCollection: any
  maxTokenData: any[]
  restOfTokenCollection: CollectionResponse[]
  loading: boolean
  error: string | null
}

const MergeCollectionsContext = createContext<
  MergeCollectionsContextType | undefined
>(undefined)

export const MergeCollectionsProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const router = useRouter()
  const systemId = useMemo(
    () =>
      router.query?.systemId?.toString() ??
      'game.piratenation.tradeablegameitems',
    [router.query?.systemId]
  )

  const supportedSystemIdChains = useMemo(
    () =>
      supportedChains.filter((supportedChain) =>
        Object.keys(nfts[systemId]?.chains || {}).includes(
          supportedChain.routePrefix
        )
      ),
    [systemId]
  )

  // Create a string representation of supportedSystemIdChains for dependency tracking
  const chainsKey = useMemo(
    () =>
      supportedSystemIdChains
        .map((chain) => chain.routePrefix)
        .sort()
        .join(','),
    [supportedSystemIdChains]
  )

  const [maxTokenCollection, setMaxTokenCollection] = useState<any>(null)
  const [maxTokenData, setMaxTokenData] = useState<any>([])
  const [restOfTokenCollection, setRestOfTokenCollection] = useState<
    CollectionResponse[]
  >([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  // Use refs to track the previous values for comparison
  const prevSystemId = useRef(systemId)
  const prevChainsKey = useRef(chainsKey)

  useEffect(() => {
    // Skip reloading if systemId and chains haven't changed
    if (
      prevSystemId.current === systemId &&
      prevChainsKey.current === chainsKey &&
      maxTokenCollection !== null
    ) {
      return
    }

    // Update refs with current values
    prevSystemId.current = systemId
    prevChainsKey.current = chainsKey

    let isMounted = true
    const loadCollections = async () => {
      if (!systemId || supportedSystemIdChains.length === 0) {
        if (isMounted) {
          setLoading(false)
        }
        return
      }

      setLoading(true)

      try {
        // Fetch collections for each supported chain
        const collectionDataPromises = supportedSystemIdChains.map(
          async (chain) => {
            const contractId = nfts?.[systemId]?.chains?.[chain.routePrefix]
            if (!contractId) return null

            try {
              const collectionData = await fetchCollectionData(
                contractId,
                chain.reservoirBaseUrl
              )

              // Return the collection dat
              return collectionData
            } catch (error) {
              console.error(
                `Error fetching data for chain ${chain.routePrefix}:`,
                error
              )
              return null
            }
          }
        )

        // Wait for all promises to resolve
        const collectionsResults = await Promise.all(collectionDataPromises)

        // Filter out empty results
        const validCollections = collectionsResults.filter(Boolean)

        if (validCollections.length > 0 && isMounted) {
          // Format the data as expected by partitionCollectionsByTokenCount
          const formattedCollections = validCollections.map(
            (collection) => collection
          )

          // Process collections to find the max token collection
          const result = partitionCollectionsByTokenCountV2(
            formattedCollections as any
          )

          if (isMounted) {
            setMaxTokenCollection(result.maxTokenCollection)
            setRestOfTokenCollection(result.restOfTokenCollection || [])
            setMaxTokenData(result.maxTokenData || [])
          }
        }
      } catch (err: any) {
        console.error('Error loading collections:', err)
        if (isMounted) {
          setError(err.message)
        }
      } finally {
        if (isMounted) {
          setLoading(false)
        }
      }
    }

    loadCollections()

    // Cleanup function to prevent state updates after unmount
    return () => {
      isMounted = false
    }
  }, [systemId, chainsKey, maxTokenCollection])

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      maxTokenCollection,
      maxTokenData,
      restOfTokenCollection,
      loading,
      error,
    }),
    [maxTokenCollection, maxTokenData, restOfTokenCollection, loading, error]
  )

  return (
    <MergeCollectionsContext.Provider value={contextValue}>
      {children}
    </MergeCollectionsContext.Provider>
  )
}

export const useMergeCollectionsContext = () => {
  const context = useContext(MergeCollectionsContext)
  if (!context) {
    throw new Error(
      'useMergeCollectionsContext must be used within a MergeCollectionsProvider'
    )
  }
  return context
}
