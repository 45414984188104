'use client'

import { PrivyProvider as PrivySdkProvider } from '@privy-io/react-auth'
import { PropsWithChildren } from 'react'
import { popChainsConfig } from 'lib/viem/chains'
import { mainnet, sepolia, Chain } from 'viem/chains'
import {
  WagmiProvider as PrivyWagmiProvider,
  createConfig,
} from '@privy-io/wagmi'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { http } from 'wagmi'
import { Transport } from 'viem'
import supportedChains from 'utils/chains'
import { ReservoirChain } from '@reservoir0x/reservoir-sdk'

type Transports = Record<ReservoirChain['id'], Transport>

const PRIVY_APP_ID = process.env.NEXT_PUBLIC_PRIVY_APP_ID ?? ''
const PRIVY_CLIENT_ID = process.env.NEXT_PUBLIC_PRIVY_CLIENT_ID ?? ''

const queryClient = new QueryClient()

const privyWagmiConfig = createConfig({
  chains: (supportedChains.length === 0 ? [mainnet] : supportedChains) as [
    Chain,
    ...Chain[]
  ],
  transports: Object.fromEntries(
    supportedChains.map((chain) => [
      chain.id,
      http(chain.rpcUrls.default.http[0]),
    ])
  ),
  ssr: false,
})

export const PrivyProvider = ({ children }: PropsWithChildren<{}>) => (
  <PrivySdkProvider
    appId={PRIVY_APP_ID}
    clientId={PRIVY_CLIENT_ID}
    config={{
      // Customize Privy's appearance in your app
      appearance: {
        loginMessage: 'Login to your Proof of Play account',
        landingHeader: 'Login or signup',
        theme: 'dark',
        accentColor: '#696FFD',
        walletList: [
          'metamask',
          'rabby_wallet',
          'wallet_connect',
          'detected_wallets',
        ],
      },
      // Don't create wallets by default
      embeddedWallets: {
        createOnLogin: 'off',
      },
      supportedChains: [mainnet, sepolia, ...popChainsConfig],
    }}
  >
    <QueryClientProvider client={queryClient}>
      <PrivyWagmiProvider config={privyWagmiConfig} reconnectOnMount={true}>
        {children}
      </PrivyWagmiProvider>
    </QueryClientProvider>
  </PrivySdkProvider>
)
