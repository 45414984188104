import { paths } from '@reservoir0x/reservoir-sdk'
import fetcher, { headers } from './fetcher'
import {
  useTokens,
  DynamicTokens,
  useDynamicTokens,
} from '@reservoir0x/reservoir-kit-ui'
import { DynamicCollectionToken } from 'hooks/liquidity/useMergeChainDynamicTokens'
import useChainCurrency from 'hooks/useChainCurrency'
import { getSystemIdChains } from 'utils/multichain'
import { NORMALIZE_ROYALTIES } from 'pages/_app'
import {
  getAssociatedMultichainReservoirChains,
  getContractAddressForSystemAndChain,
} from 'config/routes'
import { useCallback } from 'react'

export type CollectionSSRData = {
  collection?: paths['/collections/v7']['get']['responses']['200']['schema']
  tokens?: paths['/tokens/v6']['get']['responses']['200']['schema']
  hasAttributes: boolean
}

export const defaultTokensQuery: paths['/tokens/v6']['get']['parameters']['query'] =
  {
    sortBy: 'floorAskPrice',
    sortDirection: 'asc',
    limit: 20,
    normalizeRoyalties: true,
    includeDynamicPricing: true,
    includeAttributes: true,
    includeQuantity: true,
    includeLastSale: true,
  }

export async function fetchCollectionData(
  collectionId: string,
  reservoirBaseUrl: string,
): Promise<CollectionSSRData> {
  const collectionQuery: paths['/collections/v7']['get']['parameters']['query'] =
    {
      id: collectionId,
      includeSalesCount: true,
      normalizeRoyalties: true,
      //@ts-ignore: removes blur
      excludeEOA: true,
    }

  const tokensQuery: paths['/tokens/v6']['get']['parameters']['query'] = {
    collection: collectionId,
    ...defaultTokensQuery,
  }

  const promises = await Promise.allSettled([
    fetcher(`${reservoirBaseUrl}/collections/v7`, collectionQuery, headers),
    fetcher(`${reservoirBaseUrl}/tokens/v6`, tokensQuery, headers),
  ]).catch(() => {})

  const collection =
    promises?.[0].status === 'fulfilled' && promises[0].value.data
      ? promises[0].value.data
      : {}
  const tokens =
    promises?.[1].status === 'fulfilled' && promises[1].value.data
      ? promises[1].value.data
      : {}

  const hasAttributes =
    tokens?.tokens?.some(
      (token: { token?: { attributes?: any[] } }) =>
        (token?.token?.attributes?.length || 0) > 0,
    ) || false

  return { collection, tokens, hasAttributes }
}

// Collection-token level Merging data:
// - supply
// - remainingSupply
// - lastSale
// - floorAskPrice
export const partitionCollectionsByTokenCount = (
  allCollections: paths['/collections/v7']['get']['responses']['200']['schema'][],
) => {
  // Obtains the max collection details
  let maxTokenCount = -Infinity

  let maxTokenCollection = {
    index: -Infinity,
    collection: {},
    chainId: -Infinity,
  }
  let restOfTokenCollection: paths['/collections/v7']['get']['responses']['200']['schema'][] =
    []

  allCollections.forEach((collection, idx) => {
    const currCollection = collection?.collections
    if (currCollection && currCollection.length > 0) {
      const currTokenCount = Number(currCollection[0]?.tokenCount ?? 0)

      if (currTokenCount > maxTokenCount) {
        maxTokenCount = Math.max(maxTokenCount, currTokenCount)
        maxTokenCollection = {
          index: idx,
          collection: currCollection[0],
          chainId: currCollection[0].chainId,
        }
        restOfTokenCollection = allCollections.filter(
          (_, restOfIdx) => idx !== restOfIdx,
        )
      }
    }
  })

  return { maxTokenCount, maxTokenCollection, restOfTokenCollection }
}

export const partitionCollectionsByTokenCountV2 = (details: any[]) => {
  // Obtains the max collection details
  let maxTokenCount = -Infinity

  let maxTokenCollection = {
    index: -Infinity,
    collection: {},
    chainId: -Infinity,
  }
  let restOfTokenCollection: paths['/collections/v7']['get']['responses']['200']['schema'][] =
    []
  let maxTokenData: any[] = []

  details?.forEach((curr, idx) => {
    const currCollection = curr.collection?.collections
    if (currCollection && currCollection.length > 0) {
      const currTokenCount = Number(currCollection[0]?.tokenCount ?? 0)

      if (currTokenCount > maxTokenCount) {
        maxTokenCount = Math.max(maxTokenCount, currTokenCount)
        maxTokenCollection = {
          index: idx,
          collection: currCollection[0],
          chainId: currCollection[0].chainId,
        }
        restOfTokenCollection = details.filter(
          (_, restOfIdx) => idx !== restOfIdx,
        )
        maxTokenData = curr.tokens.tokens
      }
    }
  })

  return {
    maxTokenCount,
    maxTokenCollection,
    restOfTokenCollection,
    maxTokenData,
  }
}

export const findLastTokenSale = (tokenA: any, tokenB: any) => {
  let lastTokenSale = null
  const tokenALastSale = tokenA?.token?.lastSale
  const tokenBLastSale = tokenB?.token?.lastSale
  // 1. If maxToken has last sale but currCollectionToken has no last sale
  // 2. If currCollectionToken has last sale but maxToken has no last sale
  // 3. If both maxToken + currCollectionToken has last sale, then compare the timestamp
  if (tokenALastSale && !tokenBLastSale) {
    lastTokenSale = tokenALastSale
  } else if (!tokenALastSale && tokenBLastSale) {
    lastTokenSale = tokenBLastSale
  } else if (tokenALastSale && tokenBLastSale) {
    const tokenATimestamp =
      tokenALastSale?.timestamp ?? Math.floor(Date.now() / 1000)
    const tokenBTimestamp = tokenBLastSale?.timestamp

    if (tokenBTimestamp !== undefined) {
      lastTokenSale =
        tokenATimestamp < tokenBTimestamp ? tokenBLastSale : tokenALastSale
    }
  }
  return lastTokenSale
}

export const findTokenFloorPrice = (tokenA: any, tokenB: any) => {
  let tokenFloorAskPrice = {
    floorAsk: null,
    updatedTokenOwner: null,
    updatedContractId: null,
    updatedChainId: null,
  }

  // If there are prices to compare, compare price first
  if (tokenA?.market?.floorAsk?.id && tokenB?.market?.floorAsk?.id) {
    const tokenAUSDPrice = tokenA?.market?.floorAsk?.price?.amount?.usd ?? 0
    const tokenBUSDPrice = tokenB?.market?.floorAsk?.price?.amount?.usd ?? 0

    const tokenFloorPriceUSD = Math.min(tokenAUSDPrice, tokenBUSDPrice)

    if (tokenFloorPriceUSD === tokenAUSDPrice) {
      tokenFloorAskPrice = {
        ...tokenFloorAskPrice,
        floorAsk: tokenA?.market?.floorAsk,
      }
    } else if (tokenFloorPriceUSD === tokenBUSDPrice) {
      tokenFloorAskPrice = {
        floorAsk: tokenB?.market?.floorAsk,
        updatedTokenOwner: tokenB?.token.owner,
        updatedChainId: tokenB?.token.chainId,
        updatedContractId: tokenB?.token?.contract,
      }

      // If current token floor price is lower than tokenA (maxToken), we need to override and update the token.token?.owner
    } else if (tokenAUSDPrice === tokenBUSDPrice) {
      // If it is the same price on both collections, compare the validUntil and take the lesser value ending sooner
      const minValidUntil = Math.min(
        tokenA?.market?.floorAsk?.validUntil ?? 0,
        tokenB?.market?.floorAsk?.validUntil ?? 0,
      )
      if (minValidUntil > 0) {
        tokenFloorAskPrice = {
          ...tokenFloorAskPrice,
          floorAsk:
            minValidUntil === tokenA?.market?.floorAsk?.validUntil
              ? tokenA?.market?.floorAsk
              : tokenB?.market?.floorAsk,
        }
      }
    }
  }

  return tokenFloorAskPrice
}

export const findTokenTopBid = (tokenA: any, tokenB: any) => {
  let tokenTopBid = {
    topBid: null,
    updatedTokenOwner: null,
    updatedContractId: null,
    updatedChainId: null,
  }

  // If there are prices to compare, compare price first
  if (tokenA?.market?.topBid?.id && tokenB?.market?.topBid?.id) {
    const tokenAUSDPrice = tokenA?.market?.topBid?.price?.amount?.usd ?? 0
    const tokenBUSDPrice = tokenB?.market?.topBid?.price?.amount?.usd ?? 0

    const tokenTopBidUSD = Math.max(tokenAUSDPrice, tokenBUSDPrice)

    if (tokenTopBidUSD === tokenAUSDPrice) {
      tokenTopBid = { ...tokenTopBid, topBid: tokenA?.market?.topBid }
    } else if (tokenTopBidUSD === tokenBUSDPrice) {
      tokenTopBid = {
        topBid: tokenB?.market?.topBid,
        updatedTokenOwner: tokenB?.token.owner,
        updatedChainId: tokenB?.token.chainId,
        updatedContractId: tokenB?.token?.contract,
      }

      // If current token top bid price is (higher?) than tokenA (maxToken), we need to override and update the token.token?.owner
    } else if (tokenAUSDPrice === tokenBUSDPrice) {
      // If it is the same price on both collections, compare the validUntil and take the lesser value ending sooner
      const minValidUntil = Math.max(
        tokenA?.market?.topBid?.validUntil ?? 0,
        tokenB?.market?.topBid?.validUntil ?? 0,
      )
      if (minValidUntil > 0) {
        tokenTopBid = {
          ...tokenTopBid,
          topBid:
            minValidUntil === tokenA?.market?.topBid?.validUntil
              ? tokenA?.market?.topBid
              : tokenB?.market?.topBid,
        }
      }
    }
  }

  return tokenTopBid
}
export const getFloorAsk = (
  maxToken: any,
  currCollectionToken: any,
  tokenFloorAskPrice: any,
) => {
  // if token floor ask price exists take tokenFloorAskPrice
  // else if maxtoken has floor ask take max token's floor ask if not take alternative token floor ask
  return tokenFloorAskPrice !== null
    ? tokenFloorAskPrice
    : maxToken?.market?.floorAsk?.id
      ? maxToken?.market?.floorAsk
      : currCollectionToken?.market?.floorAsk
}

// TODO: replace unmergedCollectionsData: any[] paths['/collections/v7']['get']['responses']['200']['schema']
export const mergeCollectionTokens = async (
  unmergedCollectionsData: any[],
  unmergedTokensData: paths['/tokens/v6']['get']['responses']['200']['schema'][],
  systemId: string,
) => {
  const collectionsTokenData = partitionCollectionsByTokenCount(
    unmergedCollectionsData,
  )
  if (collectionsTokenData.maxTokenCount < 0) {
    return []
  }
  const { supportedSystemIdChains } = getSystemIdChains(systemId)

  const restOfCollectionsChainConfig = supportedSystemIdChains.filter(
    (supportedChain) =>
      supportedChain.id !== collectionsTokenData.maxTokenCollection.chainId,
  )
  let mergedTokensData: any[] = []

  // First get maxTokenCollection tokenIds
  // Stripped just the max collection tokenIds to feed to rest of collections token query
  const maxTokenData =
    unmergedTokensData[collectionsTokenData.maxTokenCollection.index]
  const maxTokenCollectionTokenIds = maxTokenData.tokens?.map(
    (maxToken) => maxToken?.token?.tokenId,
  )

  for (const chainConfig of restOfCollectionsChainConfig) {
    const chainId = chainConfig.id
    // Find the unmerged tokens data based on the chainId
    const collectionContractId = unmergedCollectionsData.find(
      (collectionsData) =>
        Number(collectionsData.collections[0].chainId) === Number(chainId),
    ).collections[0].id

    const tokenIds = maxTokenCollectionTokenIds?.map(
      (tokenId) => `${collectionContractId}:${tokenId}`,
    )
    const chainConfigPromise = fetcher(
      `${chainConfig?.reservoirBaseUrl}/tokens/v6`,
      {
        ...defaultTokensQuery,
        limit: 20,
        tokens: tokenIds,
      },
      headers,
    )
    const currCollectionTokensDataPromise = await Promise.allSettled([
      chainConfigPromise,
    ]).catch(() => {})

    const currCollectionTokens =
      currCollectionTokensDataPromise?.[0].status === 'fulfilled' &&
      currCollectionTokensDataPromise[0].value.data
        ? currCollectionTokensDataPromise[0].value.data
        : {}

    maxTokenData.tokens?.map((maxToken) => {
      const { token } = maxToken

      const currCollectionToken = currCollectionTokens?.tokens?.find(
        (lessTokenCollection: any) =>
          Number(lessTokenCollection?.token?.tokenId ?? '0') ===
          Number(token?.tokenId ?? '0'),
      )

      // If found the tokenId in the rest of the collections (sometimes it may not exist on the other chains)
      // then merge all the data together
      if (currCollectionToken) {
        const updatedSupply =
          Number(token?.supply) + Number(currCollectionToken?.token?.supply)
        const updatedRemainingSupply =
          Number(token?.remainingSupply) +
          Number(currCollectionToken?.token?.remainingSupply)

        const lastTokenSale = findLastTokenSale(maxToken, currCollectionToken)
        const tokenFloorAskPrice = findTokenFloorPrice(
          maxToken,
          currCollectionToken,
        )

        mergedTokensData.push({
          token: {
            ...maxToken.token,
            supply: updatedSupply.toString(),
            remainingSupply: updatedRemainingSupply.toString(),
            lastSale: lastTokenSale,
          },
          market: {
            floorAsk:
              tokenFloorAskPrice !== null
                ? tokenFloorAskPrice
                : maxToken?.market?.floorAsk?.id
                  ? maxToken?.market?.floorAsk
                  : currCollectionToken?.market?.floorAsk,
          },
          updatedAt: new Date().toISOString(),
        })
      } else {
        mergedTokensData.push(maxToken)
      }
    })
  }

  return mergedTokensData
}

// Given a current token, find if rest of the collections to merge has same token Id if so, merge the data and return merged data of maxToken.tokenId
export const mergeTokensData = (
  maxToken: ReturnType<typeof useTokens>['data'][0],
  restOfCollectionToken: DynamicCollectionToken[],
) => {
  const { token: maxTokenDetails } = maxToken
  const currTokenId = maxTokenDetails?.tokenId ?? ''

  // based on maxTokenDetails' tokenId
  const eligibleRestOfCollectionTokenDetails = []
  for (const collectionToken of restOfCollectionToken) {
    if (collectionToken.tokens.length === 0) {
      continue
    } else if (collectionToken.tokens.length > 0) {
      // Find the tokenId we are trying to merge and compare against
      const collectionTokenDetails = collectionToken.tokens.find(
        (collectionToken: { token?: { tokenId?: string } }) =>
          collectionToken.token?.tokenId === currTokenId,
      )
      if (collectionTokenDetails) {
        eligibleRestOfCollectionTokenDetails.push(collectionTokenDetails)
      }
    }
  }
  if (eligibleRestOfCollectionTokenDetails.length === 0) {
    return maxToken
  } else if (eligibleRestOfCollectionTokenDetails.length > 0) {
    // If found the tokenId in the rest of the collections (sometimes it may not exist on the other chains)
    // then update the supply, remainingSupply
    for (const currCollectionToken of eligibleRestOfCollectionTokenDetails) {
      const updatedSupply =
        Number(maxTokenDetails?.supply) +
        Number(currCollectionToken?.token?.supply)
      const updatedRemainingSupply =
        Number(maxTokenDetails?.remainingSupply) +
        Number(currCollectionToken?.token?.remainingSupply)

      const lastTokenSale = findLastTokenSale(maxToken, currCollectionToken)
      const tokenFloorAskPrice = findTokenFloorPrice(
        maxToken,
        currCollectionToken,
      )

      return {
        token: {
          ...maxToken.token,
          supply: updatedSupply.toString(),
          remainingSupply: updatedRemainingSupply.toString(),
          lastSale: lastTokenSale,
        },
        market: {
          floorAsk:
            tokenFloorAskPrice !== null
              ? tokenFloorAskPrice
              : maxToken?.market?.floorAsk?.id
                ? maxToken?.market?.floorAsk
                : currCollectionToken?.market?.floorAsk,
        },
        updatedAt: new Date().toISOString(),
      }
    }
  }
}

// Get collection details and stats for the details tab
export const mergeCollectionStats = (
  allCollections: paths['/collections/v7']['get']['responses']['200']['schema'][],
) => {
  const chainCurrency = useChainCurrency()

  const initialStats = {
    tokenCount: 0,
    floorAsk: Infinity,
    floorAskPrice: '',
    topBid: -Infinity,
    topBidPrice: '',
    volume24h: 0,
    volume24hPrice: '',
    salesCount24h: 0,
  }

  const mergedStats = allCollections.reduce((stats, { collections }) => {
    if (!collections?.[0]) return stats

    const collection = collections[0]
    const { floorAsk, topBid, volume, salesCount, tokenCount } = collection
    const floorAskDecimal = floorAsk?.price?.amount?.decimal ?? Infinity
    const topBidDecimal = topBid?.price?.amount?.decimal ?? -Infinity
    const volumeDay = Number(volume?.['1day']?.toFixed(2) ?? 0)

    return {
      tokenCount: Math.max(stats.tokenCount, Number(tokenCount || 0)),
      floorAsk: Math.min(stats.floorAsk, floorAskDecimal),
      floorAskPrice:
        floorAskDecimal < stats.floorAsk
          ? `${floorAskDecimal} ${floorAsk?.price?.currency?.symbol ?? ''}`
          : stats.floorAskPrice,
      topBid: Math.max(stats.topBid, topBidDecimal),
      topBidPrice:
        topBidDecimal > stats.topBid
          ? `${topBidDecimal} ${topBid?.price?.currency?.symbol ?? ''}`
          : stats.topBidPrice,
      volume24h: Math.max(stats.volume24h, volumeDay),
      volume24hPrice: `${volumeDay} ${chainCurrency.symbol}`,
      salesCount24h: Math.max(
        stats.salesCount24h,
        Number(salesCount?.['1day'] ?? 0),
      ),
    }
  }, initialStats)

  return {
    tokenCount: mergedStats.tokenCount,
    floorAsk: mergedStats.floorAskPrice,
    topBid: mergedStats.topBidPrice,
    pastDayVolume: mergedStats.volume24hPrice,
    pastDaySalesCount: mergedStats.salesCount24h,
  }
}

export const mergeAndSortByRarity = (arrays: any[][]) => {
  return arrays
    .flat()
    .sort((a, b) => (b.token.rarity || 0) - (a.token.rarity || 0))
}

export const mergeRareCollectionTokens = (
  allCollections: paths['/collections/v7']['get']['responses']['200']['schema'][],
) => {
  const allRareCollectionTokens: DynamicTokens[] = []
  for (const collection of allCollections) {
    const { collections: currCollections } = collection
    if (currCollections && currCollections.length > 0) {
      const currCollection = currCollections[0]
      const currCollectionContractId = currCollection.id
      const currCollectionChainId = currCollection?.chainId

      const rareTokenQuery: Parameters<typeof useDynamicTokens>['0'] = {
        limit: 8,
        collection: currCollectionContractId,
        includeLastSale: true,
        sortBy: 'rarity',
        sortDirection: 'asc',
      }

      const { data: rareTokensData } = useDynamicTokens(
        rareTokenQuery,
        {},
        currCollectionChainId,
      )
      if (rareTokensData.length > 0) {
        allRareCollectionTokens.push(rareTokensData)
      }
    }
  }
  return mergeAndSortByRarity(allRareCollectionTokens)
}

// Given a token, check rest of its associated multi-chain collections and merge the token data.
export const mergeToken = async (
  token: ReturnType<typeof useTokens>['data'][0],
  systemId: string,
): Promise<typeof token> => {
  if (!token) {
    return token
  }
  let mergedTokenDetails = { ...token }
  const tokenId = token?.token?.tokenId
  // Get all its associated token chain configs
  const chains = getAssociatedMultichainReservoirChains(
    token.token?.collection?.id || '',
  )

  // Loop through all associated multi-chain reservoir chains, get token data and merge
  const chainPromises = chains.map(async (currChain) => {
    const collectionId = getContractAddressForSystemAndChain(
      systemId,
      currChain.routePrefix,
    )
    const tokensQuery: paths['/tokens/v6']['get']['parameters']['query'] = {
      tokens: [`${collectionId}:${tokenId}`],
      includeTopBid: true,
      ...defaultTokensQuery,
    }

    try {
      const tokensResponse = await fetcher(
        `${currChain.reservoirBaseUrl}/tokens/v6`,
        tokensQuery,
        headers,
      )

      const currToken = tokensResponse.data || {}

      if (currToken.tokens?.length > 0) {
        const {
          floorAsk: tokenFloorAskPrice,
          updatedTokenOwner: updatedFloorAskTokenOwner,
          updatedChainId: updatedTokenFloorChainId,
          updatedContractId: updatedTokenFloorContractId,
        } = findTokenFloorPrice(token, currToken.tokens[0])
        // TODO: Check if updated token owner, chainId, contractId is required for top bid merging
        const { topBid: tokenTopBid } = findTokenTopBid(
          token,
          currToken.tokens[0],
        )

        return {
          market: {
            floorAsk: tokenFloorAskPrice,
            topBid: tokenTopBid,
          },
          token: {
            owner: updatedFloorAskTokenOwner,
            chainId: updatedTokenFloorChainId,
            contract: updatedTokenFloorContractId,
          },
        }
      }
    } catch (error) {
      console.error(
        `Error fetching token data for chain ${currChain.routePrefix}:`,
        error,
      )
    }
    return null
  })

  const results = await Promise.all(chainPromises)

  // Merge all valid results into mergedTokenDetails
  results.forEach((result) => {
    if (result) {
      mergedTokenDetails = {
        ...mergedTokenDetails,
        market: {
          // @ts-ignore
          floorAsk: result.market.floorAsk,
          // @ts-ignore
          topBid: result.market.topBid,
        },
        // @ts-ignore
        token: {
          ...mergedTokenDetails.token,
          ...(result.token.owner !== null && { owner: result.token.owner }),
          ...(result.token.chainId !== null && {
            chainId: result.token.chainId,
          }),
          ...(result.token.contract !== null && {
            contract: result.token.contract,
          }),
        },
      }
    }
  })

  return mergedTokenDetails
}

export const createDataHandler = (
  setter: React.Dispatch<React.SetStateAction<any[]>>,
) =>
  useCallback(
    (data: any[]) => {
      if (data.length === 0) {
        // Clear existing data when an empty array is passed
        setter([])
      } else {
        // Append new data to existing data
        setter((prev) => [...prev, ...data])
      }
    },
    [setter],
  )
